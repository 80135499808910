import "./src/styles/_base.scss"

// Packages
import { anchorate } from "anchorate"

// Scroll to anchor link (e.g '/#anchor-id')
export const onRouteUpdate = ({ location }) => {
  // Scroll to anchor logic
  anchorate({
    scroller: function(element) {
      if (!element) return false
      element.scrollIntoView({ behavior: "smooth" })
      return true
    },
  })

  // Send page view event when route changes (for Google Analytics)
  if (process.env.NODE_ENV !== "production" || typeof gtag !== "function") {
    return
  }

  const pagePath = location?.pathname + location.search + location.hash
  gtag("event", "page_view", { page_path: pagePath })
}

export const onServiceWorkerUpdateReady = () => {
  window.location.reload()
}
